import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Button } from "../Buttons"
import {
  Description,
  GridProject,
  ImageContainer,
  InfoProject,
  TitleProject,
} from "./styled"

const Project = ({ firstImage, title, excerpt, url, image }) => {
  const imagePath = getImage(image)

  return (
    <GridProject firstImage={firstImage}>
      <InfoProject>
        <TitleProject to={url}>{title}</TitleProject>
        <Description>{excerpt}</Description>
        <Button text="Ver Proyecto" url={url} isBlue="true" />
      </InfoProject>
      <ImageContainer to={url} aria-label="Link to Project">
        <GatsbyImage
          image={imagePath}
          alt={title}
          imgStyle={{
            borderRadius: "10px",
          }}
        />
      </ImageContainer>
    </GridProject>
  )
}

export default Project
