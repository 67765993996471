import { Link } from "gatsby"
import styled from "styled-components"

export const GridProject = styled.section`
  display: flex;
  gap: 1rem;
  flex-direction: ${props => (props.firstImage ? "row-reverse" : "row")};
  justify-content: space-around;
  margin-bottom: 4rem;
  margin-top: 1rem;
  min-width: 100%;
  animation: ProjectsAnimation 1s cubic-bezier(0.2, 0.82, 0.2, 1);
  @keyframes ProjectsAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`
export const InfoProject = styled.article`
  width: 300px;
  @media (max-width: 375px) {
    width: 270px;
    margin-top: 1rem;
  }
`
export const TitleProject = styled(Link)`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 0.7rem;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.action};
  }
  @media (max-width: 800px) {
    margin-top: 0.5rem;
  }
`
export const Description = styled.p`
  font-size: 1rem;
  text-align: left;
  margin-top: 0.7rem;
`
export const ImageContainer = styled(Link)`
  width: 470px;
  height: 90%;
  background: linear-gradient(60deg, #4e89ae 30%, #ed6663 60%);
  border-radius: 20px;
  padding: 1rem;
  display: grid;
  place-items: center;
  box-shadow: 0.6px 0.7px 1px ${({ theme }) => theme.shadow},
    3.3px 3.9px 5.7px -0.4px ${({ theme }) => theme.shadow},
    6.2px 7.3px 10.8px -0.7px ${({ theme }) => theme.shadow},
    10.2px 12px 17.7px -1.1px ${({ theme }) => theme.shadow},
    16.3px 19.1px 28.2px -1.4px ${({ theme }) => theme.shadow},
    25.5px 29.9px 44.2px -1.8px ${({ theme }) => theme.shadow},
    38.8px 45.5px 67.3px -2.1px ${({ theme }) => theme.shadow},
    57.2px 67px 99.1px -2.5px ${({ theme }) => theme.shadow};
  transition: 0.3s ease;
  &:hover {
    transform: translateY(-20px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.9);
  }
  @media (max-width: 800px) {
    width: 340px;
    height: auto;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 320px) {
    width: 280px;
    height: auto;
  }
`
