import loadable from "@loadable/component"
import { graphql, useStaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/Layout"
import Project from "../components/Project"
import Seo from "../components/seo"
import { TitlePageProjects } from "../components/Texts"

const FrontendChallenge = loadable(() => import("../components/Challenges"))

const ProjectsPage = () => {
  const data = useStaticQuery(graphql`
    {
      blogIndex: allMdx(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            id
            excerpt
            frontmatter {
              title
              path
              featuredImage {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: TRACED_SVG
                    width: 470
                  )
                }
              }
            }
          }
        }
      }

      imageSEO: file(relativePath: { eq: "page-projects.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, formats: [PNG])
        }
      }
    }
  `)

  const { edges: posts } = data.blogIndex

  const imagePath = getSrc(data.imageSEO)

  return (
    <Layout projects>
      <Seo
        title="Projects"
        ogImage={imagePath}
        description="Proyectos Personales + retos  de diversos sitios como Frontend Mentor, Dev Challenge.io"
      />
      <TitlePageProjects>Proyectos</TitlePageProjects>
      {posts.map(({ node: post }, index) => (
        <Project
          key={post.id}
          firstImage={index % 2 === 0 ? false : true}
          title={post.frontmatter.title}
          excerpt={post.excerpt}
          url={`/projects${post.frontmatter.path}`}
          image={post.frontmatter.featuredImage}
        />
      ))}
      <FrontendChallenge />
    </Layout>
  )
}

export default ProjectsPage
